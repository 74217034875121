import React, { lazy, Suspense } from 'react'
import { Route, Redirect, Switch } from 'react-router-dom'
import { ConnectedRouter } from 'connected-react-router'
import { CSSTransition, SwitchTransition } from 'react-transition-group'
import { connect } from 'react-redux'

import Layout from 'layouts'

const routes = [
  // Auth
  {
    path: '/auth/login',
    Component: lazy(() => import('pages/auth/login')),
    exact: true,
  },
  {
    path: '/auth/forgot-password',
    Component: lazy(() => import('pages/auth/forgot-password')),
    exact: true,
  },
  {
    path: '/auth/401',
    Component: lazy(() => import('pages/auth/401')),
    exact: true,
  },
  {
    path: '/auth/404',
    Component: lazy(() => import('pages/auth/404')),
    exact: true,
  },
  {
    path: '/auth/500',
    Component: lazy(() => import('pages/auth/500')),
    exact: true,
  },

  // Dashboard
  {
    path: '/dashboard/home',
    Component: lazy(() => import('pages/dashboard/home')),
    exact: true,
  },

  // Customer
  {
    path: '/customer/view',
    Component: lazy(() => import('pages/customer/customer/customer-view')),
    exact: true,
  },
  {
    path: '/customer/new',
    Component: lazy(() => import('pages/customer/customer/customer-new')),
    exact: true,
  },
  {
    path: '/customer/edit/:id',
    Component: lazy(() => import('pages/customer/customer/customer-edit')),
    exact: true,
  },
  {
    path: '/customer-group/view',
    Component: lazy(() => import('pages/customer/group/customer-group-view')),
    exact: true,
  },
  {
    path: '/customer-group/new',
    Component: lazy(() => import('pages/customer/group/customer-group-new')),
    exact: true,
  },
  {
    path: '/customer-group/edit/:id',
    Component: lazy(() => import('pages/customer/group/customer-group-edit')),
    exact: true,
  },

  // User
  {
    path: '/user/view',
    Component: lazy(() => import('pages/user/user-view')),
    exact: true,
  },
  {
    path: '/user/new',
    Component: lazy(() => import('pages/user/user-new')),
    exact: true,
  },
  {
    path: '/user/settings/:username',
    Component: lazy(() => import('pages/user/user-settings')),
    exact: true,
  },
  {
    path: '/user/edit/:username',
    Component: lazy(() => import('pages/user/user-edit')),
    exact: true,
  },

  // Catalog Product
  {
    path: '/catalog/product/view',
    Component: lazy(() => import('pages/catalog/product/product-view')),
    exact: true,
  },
  {
    path: '/catalog/product/new',
    Component: lazy(() => import('pages/catalog/product/product-new')),
    exact: true,
  },
  {
    path: '/catalog/product/edit/:id',
    Component: lazy(() => import('pages/catalog/product/product-edit')),
    exact: true,
  },

  // Catalog LedProduct
  {
    path: '/catalog/product/led/view',
    Component: lazy(() => import('pages/catalog/led/led-view')),
    exact: true,
  },
  {
    path: '/catalog/product/led/new',
    Component: lazy(() => import('pages/catalog/led/led-new')),
    exact: true,
  },
  {
    path: '/catalog/product/led/edit/:id',
    Component: lazy(() => import('pages/catalog/led/led-edit')),
    exact: true,
  },

  // Catalog Category
  {
    path: '/catalog/category/view',
    Component: lazy(() => import('pages/catalog/category/category-view')),
    exact: true,
  },
  {
    path: '/catalog/category/new',
    Component: lazy(() => import('pages/catalog/category/category-new')),
    exact: true,
  },
  {
    path: '/catalog/category/edit/:id',
    Component: lazy(() => import('pages/catalog/category/category-edit')),
    exact: true,
  },

  // Catalog Category Group
  {
    path: '/catalog/category-group/view',
    Component: lazy(() => import('pages/catalog/categorygroup/category-group-view')),
    exact: true,
  },
  {
    path: '/catalog/category-group/new',
    Component: lazy(() => import('pages/catalog/categorygroup/category-group-new')),
    exact: true,
  },
  {
    path: '/catalog/category-group/edit/:id',
    Component: lazy(() => import('pages/catalog/categorygroup/category-group-edit')),
    exact: true,
  },

  // Catalog Brand
  {
    path: '/catalog/brand/view',
    Component: lazy(() => import('pages/catalog/brand/brand-view')),
    exact: true,
  },
  {
    path: '/catalog/brand/new',
    Component: lazy(() => import('pages/catalog/brand/brand-new')),
    exact: true,
  },
  {
    path: '/catalog/brand/edit/:id',
    Component: lazy(() => import('pages/catalog/brand/brand-edit')),
    exact: true,
  },

  // Catalog Discount
  {
    path: '/catalog/discount/view',
    Component: lazy(() => import('pages/catalog/discount/discount-view')),
    exact: true,
  },
  {
    path: '/catalog/discount/new',
    Component: lazy(() => import('pages/catalog/discount/discount-new')),
    exact: true,
  },

  // Bulk
  {
    path: '/bulk/product',
    Component: lazy(() => import('pages/bulk/product')),
    exact: true,
  },
  {
    path: '/bulk/led',
    Component: lazy(() => import('pages/bulk/led')),
    exact: true,
  },
  {
    path: '/bulk/discount',
    Component: lazy(() => import('pages/bulk/discount')),
    exact: true,
  },

  // Company About
  {
    path: '/company/about/view',
    Component: lazy(() => import('pages/company/about/about-view')),
    exact: true,
  },
  {
    path: '/company/about/new',
    Component: lazy(() => import('pages/company/about/about-new')),
    exact: true,
  },
  {
    path: '/company/about/edit/:id',
    Component: lazy(() => import('pages/company/about/about-edit')),
    exact: true,
  },

  // News
  {
    path: '/news/view',
    Component: lazy(() => import('pages/news/news-view')),
    exact: true,
  },
  {
    path: '/news/new',
    Component: lazy(() => import('pages/news/news-new')),
    exact: true,
  },
  {
    path: '/news/edit/:id',
    Component: lazy(() => import('pages/news/news-edit')),
    exact: true,
  },

  // Defective
  {
    path: '/defective/view',
    Component: lazy(() => import('pages/defective/defective-view')),
    exact: true,
  },
  {
    path: '/defective/new',
    Component: lazy(() => import('pages/defective/defective-new')),
    exact: true,
  },
  {
    path: '/defective/edit/:id',
    Component: lazy(() => import('pages/defective/defective-edit')),
    exact: true,
  },

  // Order
  {
    path: '/order/view',
    Component: lazy(() => import('pages/order/order/order-view')),
    exact: true,
  },
  {
    path: '/order/new',
    Component: lazy(() => import('pages/order/order/order-new')),
    exact: true,
  },
  {
    path: '/order/edit/:id',
    Component: lazy(() => import('pages/order/order/order-edit')),
    exact: true,
  },

  // Order Delivery
  {
    path: '/order/delivery/view',
    Component: lazy(() => import('pages/order/delivery/delivery-view')),
    exact: true,
  },
  {
    path: '/order/delivery/new',
    Component: lazy(() => import('pages/order/delivery/delivery-new')),
    exact: true,
  },
  {
    path: '/order/delivery/edit/:id',
    Component: lazy(() => import('pages/order/delivery/delivery-edit')),
    exact: true,
  },

  // Monitor
  {
    path: '/monitor/audit/view',
    Component: lazy(() => import('pages/monitor/audit/audit-view')),
    exact: true,
  },
  {
    path: '/monitor/audit/view/:id',
    Component: lazy(() => import('pages/monitor/audit/audit-detail')),
    exact: true,
  },
  {
    path: '/monitor/fault/view',
    Component: lazy(() => import('pages/monitor/fault/fault-view')),
    exact: true,
  },
  {
    path: '/monitor/fault/view/:id',
    Component: lazy(() => import('pages/monitor/fault/fault-detail')),
    exact: true,
  },
  {
    path: '/monitor/log/view',
    Component: lazy(() => import('pages/monitor/log/log-view')),
    exact: true,
  },

  // Ecom Banner
  {
    path: '/ecom/banner/view',
    Component: lazy(() => import('pages/ecom/banner/banner-view')),
    exact: true,
  },
  {
    path: '/ecom/banner/new',
    Component: lazy(() => import('pages/ecom/banner/banner-new')),
    exact: true,
  },
  {
    path: '/ecom/banner/edit/:id',
    Component: lazy(() => import('pages/ecom/banner/banner-edit')),
    exact: true,
  },

  // Ecom Contact Form
  {
    path: '/ecom/contactform/view',
    Component: lazy(() => import('pages/ecom/contactform/contactform-view')),
    exact: true,
  },
  {
    path: '/ecom/contactform/edit/:id',
    Component: lazy(() => import('pages/ecom/contactform/contactform-edit')),
    exact: true,
  },

  // Inventory
  {
    path: '/inventory/view',
    Component: lazy(() => import('pages/inventory/inventory/inventory-view')),
    exact: true,
  },
  {
    path: '/inventory/new',
    Component: lazy(() => import('pages/inventory/inventory/inventory-new')),
    exact: true,
  },
  {
    path: '/inventory/edit/:id',
    Component: lazy(() => import('pages/inventory/inventory/inventory-edit')),
    exact: true,
  },

  // Inventory Region
  {
    path: '/inventory/region/view',
    Component: lazy(() => import('pages/inventory/region/region-view')),
    exact: true,
  },
  {
    path: '/inventory/region/new',
    Component: lazy(() => import('pages/inventory/region/region-new')),
    exact: true,
  },
  {
    path: '/inventory/region/edit/:id',
    Component: lazy(() => import('pages/inventory/region/region-edit')),
    exact: true,
  },

  // System
  {
    path: '/system/cache',
    Component: lazy(() => import('pages/system/cache')),
    exact: true,
  },

  {
    path: '/system/toggle/view',
    Component: lazy(() => import('pages/system/toggle/toggle-view')),
    exact: true,
  },
  {
    path: '/system/toggle/new',
    Component: lazy(() => import('pages/system/toggle/toggle-new')),
    exact: true,
  },
  {
    path: '/system/toggle/edit/:id',
    Component: lazy(() => import('pages/system/toggle/toggle-edit')),
    exact: true,
  },
]

const mapStateToProps = ({ settings }) => ({ settings })

const Router = ({ history, settings }) => {
  return (
    <ConnectedRouter history={history}>
      <Layout>
        <Route
          render={state => {
            const { location } = state
            return (
              <SwitchTransition>
                <CSSTransition
                  key={location.pathname}
                  appear
                  classNames={settings.routerAnimation}
                  timeout={settings.routerAnimation === 'none' ? 0 : 300}
                >
                  <Switch location={location}>
                    <Route exact path="/" render={() => <Redirect to={settings.initialPage} />} />
                    {routes.map(({ path, Component, exact }) => (
                      <Route
                        path={path}
                        key={path}
                        exact={exact}
                        render={() => {
                          return (
                            <div className={settings.routerAnimation}>
                              <Suspense fallback={null}>
                                <Component />
                              </Suspense>
                            </div>
                          )
                        }}
                      />
                    ))}
                    <Redirect to="/auth/404" />
                  </Switch>
                </CSSTransition>
              </SwitchTransition>
            )
          }}
        />
      </Layout>
    </ConnectedRouter>
  )
}

export default connect(mapStateToProps)(Router)
